import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

import Axios from "../../Helpers/Universal/Axios/Axios";
import API from "../../Helpers/Constants/EndPoints/Api";
import { USER_TYPE } from "../../Helpers/Constants/Default";
import { TrasnferAccSchema } from "../../Helpers/Constants/Schemas";
import { TransferAccFields } from "../../Helpers/Constants/InitialValues";

const TransferAcc = ({
  setVisible,
  transferAccData,
  setTransferAccData,
  setData,
}) => {
  const [supervisors, setSupervisors] = useState([]);
  const [initialValues] = useState(TransferAccFields.required);

  const fetchSupervisors = async () => {
    try {
      const { status, data } = await Axios.get(API.DROPDOWN.USER, {
        accountType: USER_TYPE.SUPERVISOR,
      });
      if (status) {
        const res = data?.filter(
          (val) => val._id !== transferAccData.transferFromId
        );
        setSupervisors(res);
      } else hide();
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const hide = () => {
    setVisible((prev) => ({ ...prev, transferAcc: false }));
    setTransferAccData((prev) => ({
      ...prev,
      toUserName: "",
    }));
  };

  const handleToTransferAcc = (e) => {
    const toSupervisor = supervisors?.find((val) => val._id === e.target.value);

    setTransferAccData((prev) => ({
      ...prev,
      toUserName: toSupervisor?.userName,
    }));
  };

  const handleSubmit = async (values) => {
    try {
      const transferFromId = transferAccData?.transferFromId,
        transferToId = values?.transferToId;

      const { status } = await Axios.post(API.SUPERVISOR.TRANSFER_ACCOUNT, {
        transferFromId,
        transferToId,
      });
      if (status)
        setData((prev) => ({
          ...prev,
          data: prev.data?.map((val) => {
            if (val._id === transferFromId) {
              return { ...val, transferAcc: true, agentCount: 0 };
            } else if (val._id === transferToId) {
              return {
                ...val,
                agentCount: val.agentCount + (transferAccData?.agentCount || 0),
              };
            }
            return val;
          }),
        }));
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      hide();
    }
  };

  useEffect(() => {
    fetchSupervisors();
  }, []);

  return (
    <div className="bg_viewpopup" style={{ zIndex: "999999" }}>
      <div className="successfullModal  successfull delete-modal bg-white p-4 pb-0 w-50 top-50 start-50 end-0 border border-secondary rounded text-center position-absolute">
        <div className="modal-header">
          <h5>Transfer Account</h5>
          <label className="cross-btns" onClick={hide}>
            ×
          </label>
        </div>
        <div className="modal-body mt-0 p-0">
          <div>
            <p className="mt-3 mb-0">
              From <span>{transferAccData.fromUserName}</span> to{" "}
              <span>{transferAccData.toUserName}</span>
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={TrasnferAccSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ isSubmitting, errors, touched, handleChange }) => (
              <Form className="page-from">
                <div className="inner-form">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label>Select Supervisor</label>
                      <Field
                        className="form-control"
                        name="transferToId"
                        as="select"
                        onChange={(e) => {
                          handleChange(e);
                          handleToTransferAcc(e);
                        }}
                      >
                        <option value="">Click to Select</option>
                        {supervisors?.map((val) => (
                          <option key={val._id} value={val._id}>
                            {val.userName}
                          </option>
                        ))}
                      </Field>
                      {errors.transferToId && touched.transferToId ? (
                        <div className="error">{errors.transferToId}</div>
                      ) : null}
                    </div>

                    <div className="col-md-12 mb-0 mt-3 text-center">
                      <button
                        disabled={isSubmitting}
                        className="btn bg-gradient-dark d-block btn-lg mb-1"
                        type="submit"
                      >
                        Transfer
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TransferAcc;
