import { EndPoints } from "../../Helpers/Constants/EndPoints";
const {
  LOGIN,
  DASHBOARD,
  SUPER_LOGOUT,

  SUB_ADMINS,
  SUB_ADMIN,
  ADD_SUB_ADMIN,
  EDIT_SUB_ADMIN,


  SUPER_ADMINS, 
  SUPER_ADMIN,
   ADD_SUPER_ADMIN, 
  EDIT_SUPER_ADMIN,

  
  ADMINS, 
  ADMIN,    
  ADD_ADMIN, 
  EDIT_ADMIN,


  AGENTS,
  AGENT,
  ADD_AGENT,
  EDIT_AGENT,

  SUPERVISORS,
  SUPERVISOR,
  ADD_SUPERVISOR,
  EDIT_SUPERVISOR,

  MANAGERS,
  MANAGER,
  ADD_MANAGER,
  EDIT_MANAGER,

  ATTENDANCE,
  SUPERVISOR_ATTENDANCE,
  ATTENDANCE_SLOT,

  NOTIFICATION,

  CONFIG,

  CMS,
} = EndPoints;

export const sidebar = [
  {
    name: "Dashboard",
    link: [DASHBOARD],
    icon: "widgets",
  },
  {
    name: "SuperAdmins/Root Users",
    link: [SUPER_ADMINS, SUPER_ADMIN, ADD_SUPER_ADMIN, EDIT_SUPER_ADMIN],
    icon: "admin_panel_settings"
  },

  {
    name: "Admins/Regional Head",
    link: [
      ADMINS, 
      ADMIN,    
      ADD_ADMIN, 
      EDIT_ADMIN,
    ],
    icon: "account_circle",
  },
  {
    name: "Sub-Admins/Area Head",
    link: [SUB_ADMINS, SUB_ADMIN, ADD_SUB_ADMIN, EDIT_SUB_ADMIN],
    icon: "supervised_user_circle"
  },

 
  {
    name: "Agent",
    link: [AGENTS, AGENT, ADD_AGENT, EDIT_AGENT],
    icon: "directions_walk",
  },
  {
    name: "Supervisor",
    link: [SUPERVISORS, SUPERVISOR, ADD_SUPERVISOR, EDIT_SUPERVISOR],
    icon: "groups",
  },
  {
    name: "Manager",
    link: [MANAGERS, MANAGER, ADD_MANAGER, EDIT_MANAGER],
    icon: "people",
  },
  {
    name: "Agent Attendance",
    link: [ATTENDANCE, ATTENDANCE_SLOT],
    icon: "clear_all",
  },
  {
    name: "Supervisor Attendance",
    link: [SUPERVISOR_ATTENDANCE, ATTENDANCE_SLOT],
    icon: "clear_all",
  },
  {
    name: "Send Notify",
    link: [NOTIFICATION],
    icon: "campaign",
  },
  {
    name: "Config",
    link: [CONFIG],
    icon: "manage_accounts",
  },
  {
    name: "Content Management",
    link: [CMS],
    icon: "sensors",
  },
  {
    name: "Super Logout",
    link: [SUPER_LOGOUT],
    icon: "priority_high",
  },
  {
    name: "Logout",
    link: [LOGIN],
    icon: "logout",
  },
];
