import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

import PieChart from "../Common/Chart/PieChart";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";

const Dashboard = () => {
  const [dashboard, setDashboard] = useState({
    agent: { total: 0, blocked: 0, acitve: 0, rejected: 0, pending: 0 },
    supervisor: { total: 0, blocked: 0, acitve: 0, rejected: 0, pending: 0 },
    manager: { total: 0, blocked: 0, acitve: 0, rejected: 0, pending: 0 },
  });

  const getData = async () => {
    try {
      const { status, data } = await Axios.get(API.DASHBOARD.ADMIN);
      // console.log(data)
      if (status)
        setDashboard({
          ...data,
          totalUsers:
            data.agent?.total + data.supervisor?.total + data.manager?.total,
        });
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="dashboad-box">
      <div className="row">
        <div className="col-md-4">
          <div className="dashboard-inner">
            <h5>Total number of Agents</h5>
            <span>{dashboard.agent?.total}</span>
            <ProgressBar
              now={(dashboard.agent?.total / dashboard.totalUsers) * 100}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-inner">
            <h5>Total number of Supervisors</h5>
            <span>{dashboard.supervisor?.total}</span>
            <ProgressBar
              now={(dashboard.supervisor?.total / dashboard.totalUsers) * 100}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-inner">
            <h5>Total number of Managers</h5>
            <span>{dashboard.manager?.total}</span>
            <ProgressBar
              now={(dashboard.manager?.total / dashboard.totalUsers) * 100}
            />
          </div>
        </div>
      </div>
      <div className="gragh-chrt mt-4">
        <div className="row">
          <div className="col-md-4">
            <div className="chart_box">
              <PieChart title="Agents" data={dashboard.agent} />
            </div>
          </div>
          <div className="col-md-4">
          <div className="chart_box">
            <PieChart title="Supervisors" data={dashboard.supervisor} />
            </div>
          </div>
          <div className="col-md-4">
          <div className="chart_box">
            <PieChart title="Managers" data={dashboard.manager} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
