import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { HeaderContent } from "../../Helpers/Constants/Headers";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { NA, NOT_FOUND } from "../../Helpers/Constants/Default";
import { setAccType } from "../../slices/accTypeSlice";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { persistor } from "../../store";

const Header = ({ collapse }) => {
  // const { profile } = useSelector((val) => val);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await Axios.post(API.LOGOUT.ADMIN, {});
      localStorage.removeItem("token");
      dispatch(setAccType(0));
      persistor.flush();
      persistor.purge(); // Clear all persisted state from local storage
      navigate(EndPoints.LOGIN); // Redirect to login after logout
    } catch (error) {
      console.log("ERROR==>", error);
    }
  };

  return (
    <div className="container-fluid heading_top">
      <div
        className={`bg-gradient-dark text-white d-flex justify-content-between border-radius-xl rounded mt-3 shadow p-3 header_topbar align-items-center ${collapse ? "headeercollapse" : "null"
          }`}
      >
        <span className="d-flex justify-content-between align-items-center">
          <button className="header-back-btn" onClick={() => navigate(-1)}>
            <i className={`fa fa-angle-left`}></i>
          </button>
          {HeaderContent[pathname] || NOT_FOUND}
        </span>
        {/* <h6 className="mb-0 text-white font-weight">
          {profile?.name ? `Welcome ${profile?.name}` : NA}
        </h6> */}
        <button
          className="d-flex align-items-center border-0"
          to={EndPoints.LOGIN}
          onClick={handleLogout}
          style={{ background: "#3e4396" }}
        >
          <i className="material-icons opacity-10 text-white">logout</i>
        </button>
      </div>
    </div>
  );
};

export default Header;
