import React, { Fragment, useEffect, useState } from "react";

import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import DatePicker from "../Common/DatePicker";
import { CreatedAt } from "../../Helpers/Universal/Function/common";
import { LIMIT, OFFSET, USER_TYPE } from "../../Helpers/Constants/Default";
import Paginate from "../Common/Paginate";
import { Link } from "react-router-dom";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { useSelector } from "react-redux";
import DataNotFound from "../Common/DataNotFound";

const Attendance = () => {
  const paginate = useSelector((val) => val.paginate);
  const [data, setData] = useState({});
  const [visible, setVisible] = useState({ loader: false });
  const [filter, setFilter] = useState({
    limit: LIMIT,
    offset: paginate?.attendance || OFFSET,
  });
  const [dropdown, setDropdown] = useState({
    agents: [],
    supervisors: [],
    managers: [],
  });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(
        API.ATTENDANCE.ATTENDANCE,
        filter
      );
      // console.log("Upcoming data-----",data) 
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const getDropdowns = async () => {
    try {
      const [agent, supervisor, manager] = await Promise.all([
        Axios.get(API.DROPDOWN.USER, {
          accountType: USER_TYPE.AGENT,
        }),
        Axios.get(API.DROPDOWN.USER, {
          accountType: USER_TYPE.SUPERVISOR,
        }),
        Axios.get(API.DROPDOWN.USER, {
          accountType: USER_TYPE.MANAGER,
        }),
      ]);

      if (agent.status)
        setDropdown((prev) => ({ ...prev, agents: agent.data }));
      if (supervisor.status)
        setDropdown((prev) => ({ ...prev, supervisors: supervisor.data }));
      if (manager.status)
        setDropdown((prev) => ({ ...prev, managers: manager.data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const setFilters = (event, key) => {
    const value = event?.target?.value;

    value
      ? setFilter((prev) => ({ ...prev, [key]: value }))
      : setFilter((prev) => {
          delete prev[key];
          return { ...prev };
        });
  };

  const exportExcel = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.REPORT.ATTENDANCE, filter);
      if (status) window.open(data, "_blank");
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <Fragment>
      {visible.loader && <Loader />}
      <div className="container-fluid py-3">
        <div className="dateselect_navigation d-flex justify-content-between align-items-center">
          <div className="d-flex main_bar">
            <DatePicker filter={filter} setFilter={setFilter} />
            <select
              className="form-select text-start btn bg-gradient-dark btn-md mb-0 mx-2"
              onChange={(e) => setFilters(e, "agentId")}
            >
              <option value="">Agents</option>
              {dropdown.agents?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </select>
            <select
              className="form-select text-start btn bg-gradient-dark btn-md mb-0 mx-2"
              onChange={(e) => setFilters(e, "supervisorId")}
            >
              <option value="">Supervisors</option>
              {dropdown.supervisors?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </select>
            <select
              className="form-select text-start btn bg-gradient-dark btn-md mb-0 mx-2"
              onChange={(e) => setFilters(e, "managerId")}
            >
              <option value="">Managers</option>
              {dropdown.managers?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </select>
            <div className="d-flex position-relative bg-gradient-dark rounded me-2">
              <i
                className="fa fa-search position-absolute top-50 translate-middle ms-3 text-white"
                aria-hidden="true"
              ></i>
              <input
                onChange={(e) => setFilters(e, "search")}
                type="search"
                className="text-start"
                style={{ cursor: "unset" }}
                name="gsearch"
              ></input>
            </div>
          </div>
          <button
            className="btn bg-gradient-dark float-end d-flex btn-md mb-1 align-items-center h-52 data-del"
            onClick={exportExcel}
          >
            Export
          </button>
        </div>
        {data?.data?.length === 0 ? (
          <DataNotFound />
        ) : (
          <Fragment>
            <div className="overflow-table">
              <table className="page-table">
                <thead>
                  <tr>
                    <th scope="col" className="text-uppercase">
                      #
                    </th>
                    <th scope="col" className="text-uppercase">
                      Date
                    </th>
                    <th scope="col" className="text-uppercase">
                      Agent
                    </th>
                    <th scope="col" className="text-uppercase">
                      Supervisor
                    </th>
                    <th scope="col" className="text-uppercase">
                      Manager
                    </th>
                    <th scope="col" className="text-uppercase">
                      Marked Success
                    </th>
                    <th scope="col" className="text-uppercase">
                      Marked Failed
                    </th>
                    <th scope="col" className="text-uppercase">
                      No. Of Hours
                    </th>
                    <th scope="col" className="text-uppercase">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((val, i) => (
                    <tr key={val._id}>
                      <td>{++i + filter.offset}</td>
                      <td>{CreatedAt(val.createdAt)}</td>
                      <td>{val.agent?.name}</td>
                      <td>{val.supervisor?.name}</td>
                      <td>{val.manager?.name}</td>
                      <td>{val.loginDetails?.successCount}</td>
                      <td>{val.loginDetails?.failedCount}</td>
                      <td>{val.loginDetails?.minutesLogged / 60}</td>
                      <td>
                        <Link to={EndPoints.ATTENDANCE_SLOT} state={val._id}>
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginate
              count={data?.count}
              filter={filter}
              setFilter={setFilter}
              sliceKey={"attendance"}
              forcePage={paginate?.attendance / 10}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Attendance;
