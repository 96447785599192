// export const LoginFileds = {
//   email: "",
//   password: "",
// };
export const LoginFileds = {
  userName: "",
  password: "",
};

export const ForgotPasswordFileds = {
  email: "",
};

export const ResetPasswordFileds = {
  password: "",
  confirmPassword: "",
};

export const ManagerFields = {
  required: {
    name: "",
    userName: "",
    email: "",
    password: "",
    phoneNumber: "",
    address: {
      address: "",
      state: "",
      stateCode: "",
      city: "",
      pincode: "",
      country: "",
    },
  },
  optional: {},
};

export const AgentFields = {
  required: {
    name: "",
    userName: "",
    email: "",
    password: "",
    phoneNumber: "",
    parentId: "",
    address: {
      address: "",
      state: "",
      stateCode: "",
      city: "",
      pincode: "",
      country: "",
    },
  },
  optional: {},
};

export const SupervisorFields = {
  required: {
    name: "",
    userName: "",
    email: "",
    password: "",
    phoneNumber: "",
    parentId: "",
    address: {
      address: "",
      state: "",
      stateCode: "",
      city: "",
      pincode: "",
      country: "",
    },
  },
  optional: {},
};

export const SubAdminFields = {
  required: {
    name: "",
    email: "",
    password: "",
  },
  optional: {},
};
export const SuperAdminFields = {
  required: {
    name: "",
    userName: "",
    password: "",
  },
  optional: {},
};

export const SendNotifyFields = {
  required: {
    title: "",
    body: "",
  },
  optional: {},
};

export const CMSFields = {
  required: {
    title: "",
    description: "",
  },
  optional: {},
};

export const TransferAccFields = {
  required: {
    transferToId: "",
  },
  optional: {},
};

export const ConfigFields = {
  required: {
    value: "",
  },
  optional: {},
};
