import React, { useEffect, useState } from "react";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CreatedAt } from "../../Helpers/Universal/Function/common";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  BLOCK_ACCESS_STATUS,
  BLOCK_STATUS,
} from "../../Helpers/Constants/Default";

const Manager = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [visible, setVisible] = useState({ loader: false });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(
        API.MANAGER.DETAILS + "/" + state
      );
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    state ? getData() : navigate(EndPoints.MANAGERS);
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      <div className="profile-view">
        <div className="inner-agent">
          <h4>Manager Details</h4>
          <div className="row align-items-start mx-0 p-3">
            <div className="col-4 d-flex justify-content-center">
              <b>
                <i>Profile:</i>
              </b>
              <span>
                <img src={data.image} alt={data.image} />
              </span>
            </div>

            <ul className="col-8">
              <li>
                <b>
                  <i>User Id:</i>
                </b>
                <span>{data.userName}</span>
              </li>
              <li>
                <b>
                  <i>Name:</i>
                </b>
                <span>{data.name}</span>
              </li>
              {/* <li>
                <b>
                  <i>Email:</i>
                </b>
                <span>{data.email}</span>
              </li> */}
              <li>
                <b>
                  <i>Password:</i>
                </b>
                <span>{data.dcryptedPass}</span>
              </li>
              <li>
                <b>
                  <i>Block Status:</i>
                </b>
                <span>
                  {data.isBlocked
                    ? BLOCK_STATUS.BLOCKED
                    : BLOCK_STATUS.UN_BLOCKED}
                </span>
              </li>
              <li>
                <b>
                  <i>Block Access:</i>
                </b>
                <span>
                  {data.blockAccess
                    ? BLOCK_ACCESS_STATUS.ENABLE
                    : BLOCK_ACCESS_STATUS.DISABLE}
                </span>
              </li>
              <li>
                <b>
                  <i>Date:</i>
                </b>
                <span>{CreatedAt(data.createdAt)}</span>
              </li>
              {/* <div className="addresses"> */}
              <li>
                <b>
                  <i>Pincode:</i>
                </b>
                <span>{data.address?.pincode}</span>
              </li>
              <li>
                <b>
                  <i>Address:</i>
                </b>
                <span>{data.address?.address}</span>
              </li>
              <li>
                <b>
                  <i>City:</i>
                </b>
                <span>{data.address?.city}</span>
              </li>
              <li>
                <b>
                  <i>State:</i>
                </b>
                <span>{data.address?.state}</span>
              </li>
              <li>
                <b>
                  <i>Country:</i>
                </b>
                <span>{data.address?.country}</span>
              </li>
              {/* </div> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Manager;
