import React from "react";

import { Chart } from "react-google-charts";

const PieChart = ({ title, data }) => {
  // console.log(title,data)
  return (
    <Chart
      chartType="PieChart"
      data={[
        ["Task", "Hours per Day"],
        ["Active", data.active],
        ["Blocked", data.blocked],
        ["Verification Pending", data.pending],
        ["Verification Rejected", data.rejected],
      ]}
      options={{
        title,
        is3D: true,
        // tooltip: { trigger: 'none' }
        tooltip: {
          isHtml: true, // Enables HTML tooltips
          textStyle: { color: "#000" }, // Customize text color
          showColorCode: true, // Show the color in the tooltip
          // trigger: 'none', // Uncomment to disable tooltips completely
        },
      }}
      width={"100%"}
      height={"400px"}
    />
  );
};

export default PieChart;
