import React, { useEffect, useState } from "react";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Loader from "../Common/Loader";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CreatedAt } from "../../Helpers/Universal/Function/common";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

const SuperAdminView = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [visible, setVisible] = useState({ loader: false });
  
  const [permissions, setPermissions] = useState([]);

  const getData = async () => {
    try {
      setVisible({ loader: true });
      const { status, data } = await Axios.get(
        API.SUPERADMIN.DETAILS + "/" + state
      );
      console.log("API Response:", data);
      if (status) {
        setData(data);
        setPermissions(data.roleId?.permissions || []);
        console.log("Permissions:", data.roleId?.permissions);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  useEffect(() => {
    if (state) {
      getData();
    } else {
      navigate(EndPoints.SUPER_ADMIN);
    }
  }, [state]);

  const handlePermissionChange = (index, field, value) => {
    const updatedPermissions = [...permissions];
    updatedPermissions[index][field] = value;
    if (field === "all") {
      updatedPermissions[index].view = value;
      updatedPermissions[index].add = value;
    }
    setPermissions(updatedPermissions);
  };

  // Function to handle document update
  const handleUpdateDocument = async (data) => {
    try {
      console.log("Updated data:", data); // Log updated data
      const { status } = await Axios.patch(API.SUPERADMIN.UPDATE, {
        subAdminId: data.subAdminId, // Pass the relevant subAdminId
        roleId: data.roleId, // Pass the relevant roleId
        permissions: permissions,
      });
      if (status) {
        setData((prev) => ({
          ...prev,
          data: prev?.data?.map((val) =>
            val._id === data.subAdminId
              ? { ...val, roleId: { permissions: data.permissions } }
              : val
          ),
        }));
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible({ loader: false });
    }
  };

  return (
    <>
      {visible.loader && <Loader />}
      <div className="profile-view">
        <div className="inner-agent">
          <h4>Super-admin Details</h4>
          <div className="row align-items-start mx-0 p-3">
            <ul className="col-8">
              <li>
                <b>
                  <i>Name:</i>
                </b>
                <span>{data.name}</span>
              </li>
              <li>
                <b>
                  <i>UserId:</i>
                </b>
                <span>{data.userName}</span>
              </li>
              <li>
                <b>
                  <i>Password:</i>
                </b>
                <span>{data.dcryptedPass}</span>
              </li>
              <li>
                <b>
                  <i>Blocked:</i>
                </b>
                <span>{data.isBlocked ? "Blocked" : "Un-blocked"}</span>
              </li>
              <li>
                <b>
                  <i>Date:</i>
                </b>
                <span>{CreatedAt(data.createdAt)}</span>
              </li>
            </ul>
            <div className="successfull bg-white p-4 w-50 top-50 start-50 end-0 border border-secondary rounded text-center position-absolute">
              <Formik
                initialValues={{ permissions }}
                onSubmit={(values) => {
                  handleUpdateDocument({
                    subAdminId: state,
                    roleId: data.roleId._id, 
                    permissions: values,
                  });
                }}
              >
                {({ values }) => (
                  <Form>
                    <table className="table table-bordered text-center">
                      <thead className="bg-light text-dark">
                        <tr>
                          <th scope="col">Menu Name</th>
                          <th scope="col">All</th>
                          <th scope="col">View</th>
                          <th scope="col">Add</th>
                        </tr>
                      </thead>
                      <tbody>
                        {permissions.map((access, index) => (
                          <tr key={index}>
                            <td>{access.name}</td>
                            <td>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={access.all}
                                onChange={(e) =>
                                  handlePermissionChange(index, "all", e.target.checked)
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={access.view}
                                onChange={(e) =>
                                  handlePermissionChange(index, "view", e.target.checked)
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={access.add}
                                onChange={(e) =>
                                  handlePermissionChange(index, "add", e.target.checked)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end mt-5">
                      <button
                        type="submit"
                        className="btn bg-gradient-dark float-end d-flex btn-md mb-1"
                      >
                        Save
                      </button>
                      {/* <button
                        type="button"
                        className="btn btn-md mb-1 float-end d-flex"
                        style={{ backgroundColor: 'red', color: 'white' }}
                        onClick={() => { 
                          console.log()
                          setVisible(false)}}
                      >
                        Close
                      </button> */}

                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdminView;
