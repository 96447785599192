import { EndPoints } from "../../Helpers/Constants/EndPoints";

const {
  NONE,
  DASHBOARD,
  SUPER_LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,

  SUB_ADMINS,
  SUB_ADMIN,
  ADD_SUB_ADMIN,
  EDIT_SUB_ADMIN,
  ATTENDANCE_SLOT,
  SUPERVISOR_ATTENDANCE_SLOT,

  SUPER_ADMINS,
  SUPER_ADMIN,
  ADD_SUPER_ADMIN,
  EDIT_SUPER_ADMIN,

  ADMINS,
  ADMIN,
  ADD_ADMIN,
  EDIT_ADMIN,


  AGENTS,
  AGENT,
  ADD_AGENT,
  EDIT_AGENT,

  SUPERVISORS,
  SUPERVISOR,
  ADD_SUPERVISOR,
  EDIT_SUPERVISOR,

  MANAGERS,
  MANAGER,
  ADD_MANAGER,
  EDIT_MANAGER,

  ATTENDANCE,
  SUPERVISOR_ATTENDANCE,

  NOTIFICATION,

  CONFIG,

  CMS,
} = EndPoints;

export const HeaderContent = {
  [NONE]: "Dashboard",
  [DASHBOARD]: "Dashboard",
  [SUPER_LOGOUT]: "Super Logout",
  [FORGOT_PASSWORD]: "Forgot Password",
  [RESET_PASSWORD]: "Reset Password",

  [SUB_ADMINS]: "Create Users",
  [SUB_ADMIN]: "Sub Admin",
  [ADD_SUB_ADMIN]: "Create Users",
  [EDIT_SUB_ADMIN]: "Update Users",

  [SUPER_ADMINS]: "Super Admins", 
  [SUPER_ADMIN]:"Super Admins",    
  [ADD_SUPER_ADMIN]:"Create SuperAdmin", 
  [EDIT_SUPER_ADMIN]:"Update SuperAdmin",


  [ADMINS]: "Admins", 
  [ADMIN]:"Admins",    
  [ADD_ADMIN]:"Create Admin", 
  [EDIT_ADMIN]:"Update Admin",



  [AGENTS]: "Agents",
  [AGENT]: "Agent",
  [ADD_AGENT]: "Create Agent",
  [EDIT_AGENT]: "Update Agent",
  [ATTENDANCE_SLOT]: "Agent Attendance Slots",

  [SUPERVISORS]: "Supervisors",
  [SUPERVISOR]: "Supervisor",
  [ADD_SUPERVISOR]: "Create Supervisor",
  [EDIT_SUPERVISOR]: "Update Supervisor",
  [SUPERVISOR_ATTENDANCE_SLOT]: "Supervisor Attendance Slots",

  [MANAGERS]: "Managers",
  [MANAGER]: "Manager",
  [ADD_MANAGER]: "Create Manager",
  [EDIT_MANAGER]: "Update Manager",

  [ATTENDANCE]: "Agent Attendance",
  [SUPERVISOR_ATTENDANCE]: "Supervisor Attendance",

  [NOTIFICATION]: "Send Push Notification",

  [CONFIG]: "Config",

  [CMS]: "Content Management",
};
