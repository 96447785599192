import React from "react";
import ReactPaginate from "react-paginate";

import { LIMIT, LIMITATION, OFFSET } from "../../Helpers/Constants/Default";
import { useDispatch } from "react-redux";
import { setPaginate } from "../../slices/paginateSlice";

const Paginate = ({ count, filter, setFilter, sliceKey, forcePage = 0 }) => {
  // console.log(count, filter, setFilter, sliceKey)
  const dispatch = useDispatch();
  const handlePageClick = (e) => {
    dispatch(setPaginate({ name: sliceKey, value: e.selected * filter.limit }));
    setFilter((prev) => ({ ...prev, offset: e.selected * prev.limit }));
  };

  return count && count > LIMIT ? (
    <div className="pagination-btn d-flex">
      <ReactPaginate
        previousLabel={<i className={`fa fa-angle-left`}></i>}
        nextLabel={<i className={`fa fa-angle-right`}></i>}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(count / filter.limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        forcePage={forcePage}
      />
      <select
        onChange={(e) => {
          setFilter((prev) => ({ ...prev, offset: 0, limit: e.target.value }));
          dispatch(
            setPaginate({ name: sliceKey, value: OFFSET })
          );
        }}
      >
        {LIMITATION.map((val, i) => (
          <option key={i} value={val}>
            {val}
          </option>
        ))}
      </select>
    </div>
  ) : null;
};

export default Paginate;
