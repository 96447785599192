export const EndPoints = {
  NONE: "/",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  DASHBOARD: "/dashboard",
  SUPER_LOGOUT: "/super-logout",

  SUB_ADMINS: "/sub-admins",
  SUB_ADMIN: "/sub-admin",
  ADD_SUB_ADMIN: "/add-sub-admin",
  EDIT_SUB_ADMIN: "/edit-sub-admin",

  SUPER_ADMINS: "/super-admins", 
  SUPER_ADMIN: "/super-admin",    
  ADD_SUPER_ADMIN: "/add-super-admin", 
  EDIT_SUPER_ADMIN: "/edit-super-admin",

  ADMINS: "/admins", 
  ADMIN: "/admin",    
  ADD_ADMIN: "/add-admin", 
  EDIT_ADMIN: "/edit-admin",

  AGENTS: "/agents",
  AGENT: "/agent",
  ADD_AGENT: "/add-agent",
  EDIT_AGENT: "/edit-agent",
  ATTENDANCE_SLOT: "/agent-attendance-slots",

  SUPERVISORS: "/supervisors",
  SUPERVISOR: "/supervisor",
  ADD_SUPERVISOR: "/add-supervisor",
  EDIT_SUPERVISOR: "/edit-supervisor",
  SUPERVISOR_ATTENDANCE_SLOT: "/supervisor-attendance-slots",

  MANAGERS: "/managers",
  MANAGER: "/manager",
  ADD_MANAGER: "/add-manager",
  EDIT_MANAGER: "/edit-manager",

  ATTENDANCE: "/agent-attendance",
  SUPERVISOR_ATTENDANCE: "/supervisor-attendance",

  NOTIFICATION: "/notification",

  CONFIG: "/config",

  CMS: "/content-management",

  UNKNOWN: "*",
};
