import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../../config/config";

const PathLocator = ({ coordinates }) => {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const defaultCenter = coordinates[0];

  return (
    <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
      <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={defaultCenter}>
        {coordinates.map((coord, index) => (
          <Marker key={index} position={coord} />
        ))}
        <Polyline
          path={coordinates}
          options={{
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          }}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default PathLocator;
