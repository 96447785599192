import { createSlice } from "@reduxjs/toolkit";
import { OFFSET } from "../Helpers/Constants/Default";

const initialState = {
  superAdmin:OFFSET,
  admin:OFFSET,
  subAdmin: OFFSET,
  agent: OFFSET,
  supervisor: OFFSET,
  manager: OFFSET,
  attendance: OFFSET,
  notify: OFFSET,
};

const paginateSlice = createSlice({
  name: "paginate",
  initialState: initialState,
  reducers: {
    setPaginate(state, value) {
      state[value.payload?.name] = value.payload?.value;
    },
  },
});

export const { setPaginate } = paginateSlice.actions;
export default paginateSlice.reducer;
