import React, { useEffect, useState } from "react";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CreatedAt } from "../../Helpers/Universal/Function/common";
import { useLocation, useNavigate,Link } from "react-router-dom";
import {
  BLOCK_ACCESS_STATUS,
  BLOCK_STATUS,
  LIMIT,
  OFFSET,
  VERIFICATION,
} from "../../Helpers/Constants/Default";
import MapContainer from "../Common/Map/MapContainer";
import Paginate from "../Common/Paginate";
import DataNotFound from "../Common/DataNotFound";
import DatePicker from "../Common/DatePicker";

const Supervisor = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    limit: LIMIT,
    offset: OFFSET,
    supervisorId: state,
  });

  const [data, setData] = useState({});
  const [attendance, setAttendance] = useState({});
  const [visible, setVisible] = useState({ loader: false });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const [data, attendance] = await Promise.all([
        Axios.get(API.SUPERVISOR.DETAILS + "/" + state),
        Axios.get(API.SUPERVISOR.ATTENDANCE_LIST, filter),
      ]);

      if (data.status) setData(data.data);
      if (attendance.status) setAttendance(attendance.data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    state ? getData() : navigate(EndPoints.SUPERVISORS);
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      <div className="profile-view">
        <div className="row">
          <div className="col-md-6">
            <div className="inner-agent">
              <h4>Supervisor Details</h4>
              <div className="row align-items-start p-3">
                <div className="col-4 text-center">
                  <b>
                    <i>Profile:</i>
                  </b>
                  <span>
                    <img src={data.image} alt={data.image} />
                  </span>
                </div>
                <ul className="col-8">
                  <li>
                    <b>
                      <i>User Id:</i>
                    </b>
                    <span>{data.userName}</span>
                  </li>
                  <li>
                    <b>
                      <i>Name:</i>
                    </b>
                    <span>{data.name}</span>
                  </li>
                  {/* <li>
                    <b>
                      <i>Email:</i>
                    </b>
                    <span>{data.email}</span>
                  </li> */}
                  <li>
                    <b>
                      <i>Password:</i>
                    </b>
                    <span>{data.dcryptedPass}</span>
                  </li>
                  <li>
                    <b>
                      <i>Manager:</i>
                    </b>
                    <span>{data.parentId?.name}</span>
                  </li>
                  <li>
                    <b>
                      <i>Block Status:</i>
                    </b>
                    <span>
                      {data.isBlocked
                        ? BLOCK_STATUS.BLOCKED
                        : BLOCK_STATUS.UN_BLOCKED}
                    </span>
                  </li>
                  <li>
                    <b>
                      <i>Block Access:</i>
                    </b>
                    <span>
                      {data.blockAccess
                        ? BLOCK_ACCESS_STATUS.ENABLE
                        : BLOCK_ACCESS_STATUS.DISABLE}
                    </span>
                  </li>
                  <li>
                    <b>
                      <i>Verification:</i>
                    </b>
                    <span>{VERIFICATION[data.verification]}</span>
                  </li>
                  <li>
                    <b>
                      <i>Date:</i>
                    </b>
                    <span>{CreatedAt(data.createdAt)}</span>
                  </li>
                  {/* <div className="addresses"> */}
                  <li>
                    <b>
                      <i>Pincode:</i>
                    </b>
                    <span>{data.address?.pincode}</span>
                  </li>
                  <li>
                    <b>
                      <i>Address:</i>
                    </b>
                    <span>{data.address?.address}</span>
                  </li>
                  <li>
                    <b>
                      <i>City:</i>
                    </b>
                    <span>{data.address?.city}</span>
                  </li>
                  <li>
                    <b>
                      <i>State:</i>
                    </b>
                    <span>{data.address?.state}</span>
                  </li>
                  <li>
                    <b>
                      <i>Country:</i>
                    </b>
                    <span>{data.address?.country}</span>
                  </li>
                  {/* </div> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="map-right">
              {data.location?.coordinates && (
                <MapContainer
                  lat={data.location?.coordinates[1]}
                  lng={data.location?.coordinates[0]}
                />
              )}
            </div>
          </div>
        </div>
        <div className="overflow-table agent-table mt-5">
          <h3>Attendance </h3>
          <div className="d-flex">
            <DatePicker filter={filter} setFilter={setFilter} />
          </div>
          <table className="page-table">
            <thead>
              <tr>
                <th scope="col" className="text-uppercase">
                  #
                </th>
                <th scope="col" className="text-uppercase">
                  Attendance Date
                </th>
                <th scope="col" className="text-uppercase">
                  Logined Success
                </th>
                <th scope="col" className="text-uppercase">
                  Logined Failed
                </th>
                <th scope="col" className="text-uppercase">
                  No. of Hours
                </th>
                <th scope="col" className="text-uppercase">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {attendance?.data?.length === 0 ? (
                <DataNotFound />
              ) : (
                attendance?.data?.map((val, i) => (
                  <tr key={val._id}>
                    <td>{++i + filter.offset}</td>
                    <td>{CreatedAt(val.createdAt)}</td>
                    <td>{val.loginDetails?.successCount}</td>
                    <td>{val.loginDetails?.failedCount}</td>
                    <td>{val.loginDetails?.minutesLogged / 60}</td>
                    <td>
                      <Link to={EndPoints.SUPERVISOR_ATTENDANCE_SLOT} state={val._id}>
                        View
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Paginate count={data?.count} filter={filter} setFilter={setFilter} />
      </div>
    </>
  );
};

export default Supervisor;
