import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";

const Permission = ({ role, setData, pKey }) => {
  const [visible, setVisible] = useState(false);
  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    
    setInitialValues(role.permissions);
  }, [role, pKey]);

  const handleUpdateDocument = async (data) => {
    try {
      console.log("data", data);

      const { status } = await Axios.patch(API.SUB_ADMIN.UPDATE, {
        subAdminId: pKey,
        roleId: role._id,
        permissions: data,
      });
      if (status) {
        setData((prev) => ({
          ...prev,
          data: prev?.data?.map((val) =>
            val._id === pKey ? { ...val, roleId: { permissions: data } } : val
          ),
        }));
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible(false);
    }
  };

  return (
    <>
      {visible ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleUpdateDocument(values);
          }}
        >
          {({ values , setFieldValue}) => (
            <Form className="bg_viewpopup">
              <div className="successfullModal successfull bg-white p-4 w-50 top-50 start-50 end-0 border border-secondary rounded text-center position-absolute">
                <FieldArray name="permissions">
                  {() => (
                    <table className="table table-bordered text-center">
                      <thead className="bg-light text-dark">
                        <tr>
                          <th scope="col">Menu Name</th>
                          <th scope="col">All</th>
                          <th scope="col">View</th>
                          <th scope="col">Add</th>
                          {/* <th scope="col">Edit</th>
                          <th scope="col">Delete</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {values?.map((val, index) => (
                          <tr key={val._id}>
                            <td>{val.name}</td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`[${index}].all`}
                                checked={val.all}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  setFieldValue(`[${index}].all`, checked);
                                  setFieldValue(`[${index}].view`, checked);
                                  setFieldValue(`[${index}].add`, checked);
                                }}
                              />
                            </td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`[${index}].view`}
                                checked={val.view ?? val.all}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  setFieldValue(`[${index}].view`, checked);
                
                                  if (!checked) {
                                    setFieldValue(`[${index}].all`, false);
                                  }
                                }}

                              />
                            </td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`[${index}].add`}
                                checked={val.add ?? val.all}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  setFieldValue(`[${index}].add`, checked);
                
                                  if (!checked) {
                                    setFieldValue(`[${index}].all`, false);
                                  }
                                }}

                              />
                            </td>
                            {/* <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`[${index}].edit`}
                                checked={val.all || val.edit}
                              />
                            </td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`[${index}].delete`}
                                checked={val.all || val.delete}
                              />
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </FieldArray>
                <div className="d-flex justify-content-end mt-5">
                  <button type="submit" className="btn btn-success btn-sm me-2">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => setVisible(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <i
          className="fa fa-life-ring fs-6 text-success pe-auto"
          aria-hidden="true"
          onClick={() => setVisible((prev) => !prev)}
        ></i>
      )}
    </>
  );
};

export default Permission;
