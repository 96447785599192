import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slices/authSlice";
import profileReducer from "../slices/profileSlice";
import accTypeRecucer from "../slices/accTypeSlice";
import paginateReducer from "../slices/paginateSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  accType: accTypeRecucer,
  paginate: paginateReducer,
});

export default rootReducer;
